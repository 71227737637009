<template>
  <div class="my">

    <nav class="navbar navbar-light fixed-top navbar-expand-lg navwhite">
      <div class="container">
        <a class="navbar-brand my-1">
          <img src="../../../public/img/logo.png" class="logo-sm-height" height="40">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <!-- <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="" >首页</a>
            </li>
          </ul> -->
          <span class="navbar-text">
							<a class="gongzuotai">工作台</a>
							<a @click="logout" class="tuchu">退出登录</a>
            <!-- <span class="reglogbox">
              <a href="" >登录</a>
                /
              <a href="" >注册</a>
            </span> -->
							</span>
        </div>
      </div>
    </nav>

    <!--占位导航栏-->
    <div style="height:80px;" class="head-sm-height"></div>
    <!-- <el-button @click="logout" type="danger">退出登录</el-button> -->

    <div class="kanbanpage">
      <div class="workcate">
        <div class="container">
          <a href="" class="active">服务看板</a>
          <a href="">应用中心</a>
          <a href="">工单管理</a>
          <a href="">团队管理</a>
        </div>
      </div>
      <div class="kanban">
        <div class="container">
          <div class="kanbanbanner">
            <a @click="zhenduan">
              <img src="../../../public/img/kanban-banner.jpg" width="100%"/>
            </a>
          </div>
          <div class="row">

            <div class="col-md-12">
              <div class="yigouxm">
                <!--<h4>已购应用/服务</h4>-->

                <div class="fileupload">
                  <el-button :type="activeLibrary === '1' ? 'primary' : ''" @click="toggleLibrary('1')">案例库</el-button>
                  <el-button :type="activeLibrary === '2' ? 'primary' : ''" @click="toggleLibrary('2')">方案库</el-button>

                  <div v-if="activeLibrary === '1'" class="shangchuantop">
<!--                    <h3>案例库上传</h3>-->
                    <el-form ref="form" :model="form1"  :inline="true">
                      <el-form-item label="缩略图">
                        <el-upload
                            action="#"
                            list-type="picture-card"
                            :auto-upload="false"
                            :limit="1"
                            :on-change="handleChange"
                            :on-remove="handleRemove"
                            :file-list="form1.thumbnails">
                          <i slot="default" class="el-icon-plus"></i>
                          <div slot="file" slot-scope="{file}">
                            <img
                                class="el-upload-list__item-thumbnail"
                                :src="file.url" alt=""
                            >
                            <span class="el-upload-list__item-actions">
                          <span
                              class="el-upload-list__item-preview"
                              @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                              v-if="!disabled"
                              class="el-upload-list__item-delete"
                              @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                          </div>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                          <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                      </el-form-item>

                      <el-form-item label="文件名称" prop="fileName">
                        <el-input v-model="form1.fileName"></el-input>
                      </el-form-item>
                      <el-form-item label="来源" prop="source">
                        <el-input v-model="form1.source"></el-input>
                      </el-form-item>
                      <el-form-item label="场景" prop="scene">
                        <el-select v-model="form1.scene" placeholder="请选择场景">
                          <el-option label="场景1" value="scene1"></el-option>
                          <el-option label="场景2" value="scene2"></el-option>
                          <!-- 添加更多场景选项 -->
                        </el-select>
                      </el-form-item>

                    </el-form>
                  </div>

                  <div v-if="activeLibrary === '2'" class="shangchuantop">
<!--                    <h3>方案库上传</h3>-->
                    <el-form ref="form" :model="form2" :inline="true">
                      <el-form-item label="缩略图">
                        <el-upload
                            action="#"
                            list-type="picture-card"
                            :auto-upload="false"
                            :limit="1"
                            :on-change="handleChange2"
                            :on-remove="handleRemove2"
                            :file-list="form2.thumbnails">
                          <i slot="default" class="el-icon-plus"></i>
                          <div slot="file" slot-scope="{file}">
                            <img
                                class="el-upload-list__item-thumbnail"
                                :src="file.url" alt=""
                            >
                            <span class="el-upload-list__item-actions">
                        <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview2(file)"
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove2(file)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                          </div>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible2">
                          <img width="100%" :src="dialogImageUrl2" alt="">
                        </el-dialog>
                      </el-form-item>



                      <el-form-item label="文件名称" prop="fileName">
                        <el-input v-model="form2.fileName"></el-input>
                      </el-form-item>
                      <el-form-item label="来源" prop="source">
                        <el-input v-model="form2.source"></el-input>
                      </el-form-item>
                      <el-form-item label="行业" prop="industry">
                        <el-select v-model="form2.industry" placeholder="请选择行业">
                          <el-option label="行业1" value="industry1"></el-option>
                          <el-option label="行业2" value="industry2"></el-option>
                          <!-- 添加更多行业选项 -->
                        </el-select>
                      </el-form-item>
                      <el-form-item label="企业规模" prop="companySize">
                        <el-select v-model="form2.companySize" placeholder="请选择企业规模">
                          <el-option label="小型企业" value="small"></el-option>
                          <el-option label="中型企业" value="medium"></el-option>
                          <el-option label="大型企业" value="large"></el-option>
                          <!-- 添加更多企业规模选项 -->
                        </el-select>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>

              </div>
<!--              文件管理列表-->
              <div class="yigouxm">
                <div>
                  <!-- 检索条件 -->
                  <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                    <el-form-item label="所属模块">
                      <el-select v-model="searchForm.module" placeholder="请选择所属模块">
                        <el-option label="模块1" value="module1"></el-option>
                        <el-option label="模块2" value="module2"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="文件名称">
                      <el-input v-model="searchForm.fileName" placeholder="文件名称"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                      <el-select v-model="searchForm.status" placeholder="请选择状态">
                        <el-option label="上架" value="上架"></el-option>
                        <el-option label="下架" value="下架"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="searchFiles">查询</el-button>
                      <el-button type="success" @click="addFile">新增</el-button>
                    </el-form-item>
                  </el-form>

                  <!-- 表格列表 -->
                  <el-table :data="fileList" style="width: 100%">
                    <el-table-column type="index" label="序号" width="50"></el-table-column>
                    <el-table-column prop="module" label="所属模块"></el-table-column>
                    <el-table-column label="缩略图">
                      <template slot-scope="scope">
                        <img :src="scope.row.thumbnail" alt="缩略图" style="width: 50px; height: 50px;">
                      </template>
                    </el-table-column>
                    <el-table-column prop="fileName" label="文件名称"></el-table-column>
                    <el-table-column prop="source" label="来源"></el-table-column>
                    <el-table-column prop="industry" label="行业/场景"></el-table-column>
                    <el-table-column prop="status" label="状态"></el-table-column>
                    <el-table-column label="操作" width="300">
                      <template slot-scope="scope">
                        <el-button size="mini" @click="viewDetails(scope.row)">详情</el-button>
                        <el-button size="mini" @click="editFile(scope.row)">编辑</el-button>
                        <el-button size="mini" @click="deleteFile(scope.row)">删除</el-button>
                        <el-button size="mini" @click="toggleStatus(scope.row)">{{ scope.row.status === '上架' ? '下架' : '上架' }}</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>

            </div>


          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
export default {
  name: 'casebase',
  data() {
    return {
      activeLibrary: '1', // 默认显示案例库
      form1: {
        fileName: '',
        source: '',
        scene: '',
        thumbnails: []
      },
      form2: {
        fileName: '',
        source: '',
        scene: '',
        thumbnails: [],
        industry: '',
        companySize: ''
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,

      dialogImageUrl2: '',
      dialogVisible2: false,
      disabled2: false,

      searchForm: {
        module: '',
        fileName: '',
        status: ''
      },
      fileList: [
        {
          module: '模块1',
          thumbnail: 'https://via.placeholder.com/50',
          fileName: '文件1',
          source: '来源1',
          industry: '行业1',
          status: '上架'
        },
        {
          module: '模块2',
          thumbnail: 'https://via.placeholder.com/50',
          fileName: '文件2',
          source: '来源2',
          industry: '行业2',
          status: '下架'
        }
      ]

    }

  },
  components: {},

  mounted() {

  },
  methods: {
    // 中小企业数字化转型水平评测
    zhenduan() {
      this.$router.push({
        path: '/zhenduan',
        // params: {
        //   userName: this.userInfo.username,
        //   mobile: this.userInfo.mobile
        // }
      });
    },
    // 退出登录
    logout() {
      this.$confirm('是否退出当前账号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        localStorage.removeItem('mobile');
        localStorage.removeItem('uscc');
        localStorage.removeItem('company');
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
        this.$router.push('/login');
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    onSubmit() {
      console.log('submit!');
    },

  // 案例库方案库切换
    toggleLibrary(library) {
      this.activeLibrary = library;
    },
    handleChange(file, fileList) {
      this.form1.thumbnails = fileList;
    },
    handleRemove(file) {
      this.form1.thumbnails = this.form1.thumbnails.filter(f => f.uid !== file.uid);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleChange2(file, fileList) {
      this.form2.thumbnails = fileList;
    },
    handleRemove2(file) {
      this.form2.thumbnails = this.form2.thumbnails.filter(f => f.uid !== file.uid);
    },
    handlePictureCardPreview2(file) {
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true;
    },

    searchFiles() {
      // 实现查询逻辑
      console.log('查询条件:', this.searchForm);
    },
    addFile() {
      // 实现新增文件逻辑
      console.log('新增文件');
    },
    viewDetails(row) {
      // 实现查看详情逻辑
      console.log('查看详情:', row);
    },
    editFile(row) {
      // 实现编辑文件逻辑
      console.log('编辑文件:', row);
    },
    deleteFile(row) {
      // 实现删除文件逻辑
      console.log('删除文件:', row);
    },
    toggleStatus(row) {
      // 实现上架/下架逻辑
      row.status = row.status === '上架' ? '下架' : '上架';
      console.log('状态切换:', row);
    }

  }
}
</script>
<style scoped lang="scss">
.my {
  .kanbanpage {
    .kanban {
      .daiban2{
        height: auto;
        .time{
          font-size: 13px;
          color: #9b9b9b;
        }
      }
      .daiban {
        .statusItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 20px;

          span {
            font-size: 32px;
            font-weight: bold;
          }

          p {
            font-size: 18px;
          }
        }
        .contractNo{
          display: flex;
          align-items: center;
          justify-content: start;
          .hetongIcon{
            width: 24px;
            height: 24px;
            color: #2c57b6;
          }
          .ctNo{
            font-weight: bold;
            margin-left: 5px;
          }
        }
        .listMain{
          margin-top: 12px;
          .orderNo{
            font-weight: bold;
          }
          .filedBtn{
            color: #0081ff;float: right;
            background: #e9e9e9;
            padding: 5px 0;
            border-radius: 4px;
            font-size: 14px;
            width: 90px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .dialogMain{
    li{
      line-height: 24px;
      cursor: pointer;
    }
  }
  .servtit-mb{
    display: none;
  }
  .yhq-con{
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    ::v-deep .el-dialog__header{
      padding: 0;
      z-index: 999;
      position: absolute;
      right: 0;
      .el-dialog__headerbtn{
        top: 15px;
        right: 15px;
      }
    }
  }
  .yhq-tc{
    background: url("../../../public/img/yhq-bg.jpg") no-repeat;
    height:330px;    background-size: 100% 100%;
    padding: 30px 30px 10px;
    position: relative;
    .daishiyong{
      position: absolute;
      right: 0;
      top: 0;
    }
    .r-ico{
      position: absolute;
      right: 0;
      bottom: 0;
      width: 340px;
      overflow: hidden;
    }
    .yhq-title{
      font-size: 22px;color: #3971bb;font-weight: bold;padding: 5px 0;
    }
    .number{
      font-size: 75px;
      color: #3971bb;
      font-weight: bold;
      padding: 10px 50px;
      span{
        font-size:60px;
      }
    }
    .verification{
      margin: 10px 0 10px;
      font-size: 16px;
      color: #333;
      p{
        margin: 0 0 8px;
      }
      .blue{
        color: #3971bb;    padding: 0;font-size: 16px;
      }
    }
    .yhq-footer{
      font-size: 16px;
      text-align: center;
      color: #333;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, -50%);
      width: 100%;
    }

  }






  .upload-demo {
    display: inline-block;
  }
.fileupload{
  ::v-deep .el-upload__input{
    display: none;
  }
  ::v-deep .el-upload--picture-card{
    width: 60px;
    height: 60px;
    line-height: 60px
  }
  ::v-deep .el-upload--picture-card i {
    font-size: 16px;
  }
  ::v-deep .el-upload-list--picture-card .el-upload-list__item{
    width: 60px;
    height: 60px;
}
  .shangchuantop{
    margin-top:40px;
    ::v-deep .el-form--inline {
      .el-form-item__label{
        line-height: 60px;
      }
    }
    .el-input{
      margin-top: 10px;
    }
    .el-select{
      margin-top: 10px;
    }
  }
}







}
@media only screen and (max-width:768px) {
  .my{
    .navbar-brand{
      padding: 5px 0
    }
    .navbar-toggler{
      display: none;
    }
    .collapse{
      display: block;
    }
    .navbar-text{
      right: 0;top: -10px
    }
    .head-sm-height{
      height: 60px !important;
    }
    .kanbanpage .workcate a{
      padding: 0 10px;margin-right: 0;
    }
    .kanbanbanner{
      overflow: hidden;
      margin: 0 -15px;width: calc(100% + 30px);
      img{
        width: 150%;
      }
    }
    .daiban{
      height: auto;
      .statusItem{
        width: 50%;float: left;
        p{
          font-size: 16px !important;
        }
      }
    }
    .userbx{
      display: none;
    }
    .appliserv{
      .appitem{
        width: 33.33%;float: left;
        h5{
          font-size: 14px;
        }
        img{
          width: 50px;
        }
      }
    }
    .yigouxm{
      margin: 0 -15px 20px;width: calc(100% + 30px);
      padding: 10px;
      h4{
        display: none;
      }


    }
  }
  .yhq-con{
    ::v-deep .el-dialog{
      width: 90% !important;
      height: 90vh;
      margin-top: 50px !important;
      margin: 0 auto 50px;
    }
    .yhq-tc{
      background: url("../../../public/img/yhq-bgmb.jpg") no-repeat;
      height: 100%;    background-size: 100% 100%;
      padding:20% 15px 10px;
      .number{
        padding: 8% 0;
        text-align: center;
      }
      .r-ico{
        width: 100%;bottom: 50px;
      }
      .verification{
        ::v-deep .el-col-5{
          width: 60%;
        }
        ::v-deep .el-col-19{
          width: 40%;
        }
      }
    }
    ::v-deep .el-dialog__body{
      height: calc(100% - 0px);
    }
  }
}

</style>
