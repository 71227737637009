<template>
  <div class="my">

    <nav class="navbar navbar-light fixed-top navbar-expand-lg navwhite">
      <div class="container">
        <a class="navbar-brand my-1">
          <img src="../../public/img/logo.png" class="logo-sm-height" height="40">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <!-- <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="" >首页</a>
            </li>
          </ul> -->
          <span class="navbar-text">
							<a class="gongzuotai">工作台</a>
							<a @click="logout" class="tuchu" v-if="!isMobile">退出登录</a>
            <!-- <span class="reglogbox">
              <a href="" >登录</a>
                /
              <a href="" >注册</a>
            </span> -->
							</span>
        </div>
      </div>
    </nav>

    <!--占位导航栏-->
    <div style="height:80px;" class="head-sm-height"></div>
    <!-- <el-button @click="logout" type="danger">退出登录</el-button> -->

    <div class="kanbanpage">
      <div class="workcate">
        <div class="container">
          <a href="" class="active">服务看板</a>
          <a href="">应用中心</a>
          <a href="">工单管理</a>
          <a href="">团队管理</a>
        </div>
      </div>
      <div class="kanban">
        <div class="container">
          <div class="kanbanbanner">
            <a @click="zhenduan">
              <img src="../../public/img/kanban-banner.jpg" width="100%"/>
            </a>
          </div>
          <div class="row">
            <!-- <div class="col-md-8">
              <div class="daiban">
                								<h4>代办事项</h4>
                								<div class="row">
                									<div class="col-md-4">
                										<img src="../../public/img/daiban-ico1.png"/>
                										<div class="daiban-rt">
                											<h5>待处理告警</h5>
                											<h3>0</h3>
                										</div>
                									</div>
                									<div class="col-md-4">
                										<img src="../../public/img/daiban-ico2.png"/>
                										<div class="daiban-rt">
                											<h5>待支付订单</h5>
                											<h3>0</h3>
                										</div>
                									</div>
                									<div class="col-md-4">
                										<img src="../../public/img/daiban-ico3.png"/>
                										<div class="daiban-rt">
                											<h5>待处理工单</h5>
                											<h3>0</h3>
                										</div>
                									</div>
                								</div>
                <div class="row">
                  <div class="col-md-6 statusItem" @click="getWorkbenchInfo('已交付')">
                    <span style="color: #F93B07;">{{ statusNum.amount || 0 }}</span>
                    <p>已交付</p>
                  </div>
                  <div class="col-md-6 statusItem" @click="getWorkbenchInfo('未交付')">
                    <span style="color: #FCC002;">{{ statusNum.processing || 0 }}</span>
                    <p>未交付</p>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md-8">
              <div class="yigouxm">
                <h4>已购应用/服务</h4>
                <div class="yigouxm-con">
                  <div class="yigouxmli">
                    <div class="yigouxmli-l">
                      <img src="../../public/img/szhzx-l.jpg"/>
                    </div>
                    <div class="servtit-mb">
                      <img src="../../public/img/szhzx-l-mb.jpg" width="100%" height="32"/>
                    </div>
                    <div class="yigouxmli-r">
                      <ul>
                        <li>
                          <div @click="zhenduan">
                            <img src="../../public/img/szhzx-item1.jpg"/>
                            <div class="title">中小企业数字化转型水平评测</div>
                          </div>
                        </li>
                        <li>
                          <div @click="innovateClick()">
                            <img src="../../public/img/szhzx-item2.png"/>
                            <div class="title">数字化账套体验券</div>
                          </div>
                        </li>
                        <li>
                          <div @click="cardClick()">
                            <img src="../../public/img/szhzx-item3.png"/>
                            <div class="title">聚通企业数字名片</div>
                          </div>
                        </li>

                        <!--弹窗-->
                        <div class="yhq-con">
                          <!--优惠券-->
                          <el-dialog
                              :visible.sync="dialogtyq"
                              width="1000px"
                              class="mainBox"
                              :before-close="handleClose">
                            <div class="yhq-tc" v-if="Object.keys(innovateData).length!==0">
                              <div class="daishiyong">
                                <!-- 待使用-->
                                <img src="../../public/img/daishiyong.png" v-if="innovateData.status==1" width="130">
                                <!--已使用-->
                                <img src="../../public/img/yishiyong.png" v-else width="130">
                              </div>
                              <div class="r-ico">
                                <img src="../../public/img/yhq-rico.png">
                              </div>

                              <div>
                                <img src="../../public/img/logo.png" height="25">
                              </div>
                              <div class="yhq-title">聚通云企业数字化账套体验券</div>
                              <div class="number"><span>￥</span>50000</div>
                              <div class="verification">
                                <el-row :gutter="20">
                                  <el-col :span="5">
                                    <p >验证码：<span id="verification-code">{{ innovateData.verificationcode }}</span></p>
                                  </el-col>
                                  <el-col :span="19">
                                    <el-button type="text" class="blue"  @click="copyVerificationCode">复制验证码</el-button>
                                  </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                  <el-col :span="24">
                                    <p>有效期：一年</p>
                                  </el-col>
                                </el-row>
                              </div>
                              <div class="yhq-footer">
                                云端驱动创新 数据创造价值
                              </div>
                            </div>
                          </el-dialog>
                          <!--名片码-->
                          <el-dialog
                              :visible.sync="dialogtCard"
                              custom-class="transparentDialog"
                              :show-close="false"
                              center
                              :close-on-click-modal=false
                              width="30%"
                              :before-close="handleCloseCard">
                            <img src="../../public/img/cardImg.jpg" alt="名片小程序码">
                            <span slot="footer" class="dialog-footer">
                              <el-button type="primary" @click="dialogtCard = false">确 定</el-button>
<!--                              <a href="http://smalleq.winzome.com/assets/cloud/cardImg.jpg" v-if="isMobile" class="downLoadCard">保存到手机</a>-->
                            </span>
                          </el-dialog>
                        </div>
                        <li>
                          <div @click="casebase">
                            <img src="../../public/img/szhzx-item33.png"/>
                            <div class="title">数字化转型案例库</div>
                          </div>
                        </li>
                        <li>
                          <div @click="LiftingScheme">
                            <img src="../../public/img/szhzx-item44.png"/>
                            <div class="title">数字化提升方案</div>
                          </div>
                        </li>
                        <!-- <li>
                          <div>
                            <img src="../../public/img/szhzx-item2.jpg"/>
                          </div>
                        </li>
                        <li>
                          <div>
                            <img src="../../public/img/szhzx-item3.jpg"/>
                          </div>
                        </li>
                        <li>
                          <div>
                            <img src="../../public/img/szhzx-item4.jpg"/>
                          </div>
                        </li>
                        <li>
                          <div>
                            <img src="../../public/img/szhzx-item5.jpg"/>
                          </div>
                        </li>
                        <li>
                          <div>
                            <img src="../../public/img/szhzx-item6.jpg"/>
                          </div>
                        </li>
                        <li>
                          <div>
                            <img src="../../public/img/szhzx-item7.jpg"/>
                          </div>
                        </li>
                        <li>
                          <div>
                            <img src="../../public/img/szhzx-item8.jpg"/>
                          </div>
                        </li> -->
                      </ul>
                    </div>
                  </div>
                  <div class="yigouxmli">
                    <div class="yigouxmli-l">
                      <img src="../../public/img/szhkb-l.jpg"/>
                    </div>
                    <div class="servtit-mb">
                      <img src="../../public/img/szhkb-l-mb.jpg" width="100%" height="32"/>
                    </div>
                    <div class="yigouxmli-r">
                      <ul>
                        <li>
                          <a href="https://datacenter.winzome.com/datashow/enterprise" target="_blank">
                            <img src="../../public/img/szhkb-item1.jpg"/>
                            <div class="title">企业运营看板</div>
                          </a>
                        </li>
                        <li>
                          <a href="https://datacenter.winzome.com/datashow/manufacture" target="_blank">
                            <img src="../../public/img/szhkb-item2.jpg"/>
                            <div class="title">生产执行看板</div>
                          </a>
                        </li>
                        <li>
                          <a href="https://datacenter.winzome.com/datashow/safework" target="_blank">
                            <img src="../../public/img/szhkb-item3.jpg"/>
                            <div class="title">安全生产看板</div>
                          </a>
                        </li>
                        <li>
                          <a href="https://datacenter.winzome.com/datashow/greenkb" target="_blank">
                            <img src="../../public/img/szhkb-item4.jpg"/>
                            <div class="title">能源管控看板</div>
                          </a>
                        </li>
                        <!-- <li>
                          <a href="http://datacenter.winzome.com/display_v2?kb&513D421A1CC7ACE5851BA79405FDF805&/datashow/manufacture" target="_blank">
                            <img src="../../public/img/szhkb-item5.png"/>
                            <div class="title">质量管理看板</div>
                          </a>
                        </li> -->
                        <li>
                          <a :href="`https://news.winzome.com?user_id=${userInfo.id}`" target="_blank">
                            <img src="../../public/img/szhkb-item6.jpg"/>
                            <div class="title">新闻看板</div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="userbx">
                <div class="userbxmid">
                  <!-- <img :src="userInfo.avatar"/> -->
                  <img v-if="userInfo.avatar" :src="userInfo.avatar"/>
                  <img v-else src="../../public/img/user.png"/>
                  <div class="userbx-rt">
                    <h3>{{ userInfo.nickname }}</h3>
                    <h5>{{ userInfo.company_name }}</h5>
                    <h5>账号：{{ userInfo.mobile }}</h5>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-8">
              <div class="daiban daiban2">
                <div v-if="workbenchListHandle == ''">暂无数据</div>
                <div v-else>
                  <div v-for="item in workbenchListHandle" :key="item.id">
                    <div class="contractNo">
                      <svg class="icon hetongIcon" aria-hidden="true">
                        <use xlink:href="#icon-hetong"></use>
                      </svg>
                      <div class="ctNo">合同号: {{ item.contractNo }}</div>
                    </div>
                    <el-row :gutter="15" class="listMain">
                      <el-col :span="16" class="orderNo">{{ item.type_name }}</el-col>
                      <el-col :span="8">
                        <div class="filedBtn" @click="getFiled(item.id)">附件</div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="15">
                      <el-col :span="16">
                        <div>单号： {{ item.workbench_id }}</div>
                        <div class="time">开通日期: {{ item.starttime }}</div>
                        <div class="time">到期日期: {{ item.endtime }}</div>
                      </el-col>
                      <el-col :span="8">
                        <div style="width: 90px;float: right;text-align: center;margin: 5px 0 0;">
													{{ item.status }}
												</div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        详情: {{ item.content }}
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md-4">
              <div class="appliserv">
                <div class="row">
                  <div class="col-md-4 appitem">
                    <img src="../../public/img/appli-ico1.png"/>
                    <h5>订阅应用</h5>
                    <h5>{{ statusNum.amount || 0 }}款</h5>
                  </div>
                  <div class="col-md-4 appitem">
                    <img src="../../public/img/appli-ico2.png"/>
                    <h5>API服务</h5>
                    <h5>0款</h5>
                  </div>
                  <div class="col-md-4 appitem">
                    <img src="../../public/img/appli-ico3.png"/>
                    <h5>团队人数</h5>
                    <h5>0款</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="noticeann">
                <h4>通知公告 <span @click="toList">更多 ></span> </h4>
                <ul>
                  <li v-for="item in newList" :key="item.id" @click="newDetail(item.id)">
                    <div>{{ item.title }}</div>
                    <span>{{ item.createtime | formattedDate }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="附件"
        :visible.sync="dialogVisible"
        width="35%">
      <ul class="dialogMain">
        <li v-for="(item, index) in filedList" :key="item.id" @click="downloadFile(item.url)">{{index+1}} . {{ item.name }}</li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'my',
  data() {
    return {
      userInfo: [],
      mobile: '',
      statusNum: {},
      workbenchListHandle: [],
      filedList: [],
      dialogVisible: false,
      newList: [],
      dialogtyq: false,
      dialogtCard: false,
      innovateData: {}, //创新券
      isMobile: false
    }
  },
  components: {},
  filters: {
    formattedDate(val) {
      const date = new Date(val * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  mounted() {
    this.checkDeviceType();
    window.addEventListener('resize', this.checkDeviceType);
    const currentURL = window.location.href;
    const splitURL = currentURL.split('?');
    if (splitURL.length > 1) {
      const queryString = splitURL[1];
      const paramPairs = queryString.split('&');
      for (let i = 0; i < paramPairs.length; i++) {
        const [paramName, paramValue] = paramPairs[i].split('=');
        if (paramName === 'mobile') {
          localStorage.setItem('mobile', paramValue);
        }else if (paramName === 'xsId') {
          localStorage.setItem('xsId', paramValue);
        }
      }
    }
    const mobile = localStorage.getItem('mobile');
    if (mobile) {
      this.getUserInfo(mobile);
      this.getWorkbenchStatusNum(mobile);
      this.mobile = mobile;
      this.getWorkbenchInfo("已交付");
    } else {
      this.$router.push('/login');
    }
    this.getNews();
  },
  methods: {
    checkDeviceType() {
      // 你可以根据需要调整这个阈值
      this.isMobile = window.innerWidth <= 768;
    },
    // 退出登录
    logout() {
      this.$confirm('是否退出当前账号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        localStorage.removeItem('mobile');
        localStorage.removeItem('uscc');
        localStorage.removeItem('company');
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
        this.$router.push('/login');
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 根据手机号获取当前用户所有信息
    getUserInfo(mobile) {
      this.$axios.get('/http/api/wanlshop/user/getUser', {
        params: {mobile: mobile},
      }).then(res => {
        this.userInfo = res.data.data;
      }).catch((err) => {
        console.log(err)
      })
    },
    getInnovate(mobile){
      this.$axios.get('/http/api/wanlshop/user/getInnovate', {
        params: {mobile: mobile},
      }).then(res => {
        if(res.data.code===1){
          this.innovateData = res.data.data;
          this.dialogtyq = true;
        }else {
          this.$message.error(res.data.msg);
        }
      })
    },
    // 中小企业数字化转型水平评测
    zhenduan() {
      this.$router.push({
        path: '/zhenduan',
        // params: {
        //   userName: this.userInfo.username,
        //   mobile: this.userInfo.mobile
        // }
      });
    },
    /*转型案例库*/
    casebase() {
      this.$router.push({
        path: '/casebase',
      });
    },
    // 提升方案
    LiftingScheme() {
      this.$router.push({
        path: '/LiftingScheme',
      });
    },
    // 各个状态数量
    getWorkbenchStatusNum(mobile) {
      this.$axios.post('/http/api/wanlshop/workbench/workbenchStatusNum', {
        phone: mobile
      }).then(res => {
        this.statusNum = res.data.data;
      }).catch((err) => {
        console.log(err)
      })
    },
    // 工单列表
    getWorkbenchInfo(status) {
      this.workbenchListHandle = [];
      if (!status) {
        status = '已交付';
      }
      this.$axios.post('/http/api/wanlshop/workbench/workbenchList', {
        phone: this.mobile,
        status: status
      }).then(res => {
        this.applyWorkbenchList(res.data.data);
      }).catch((err) => {
        console.log(err)
      })
    },
    // 订单号相同的工单拼成新数组
    applyWorkbenchList(workbenchList) {
      let tempArr = [];
      workbenchList.forEach((item, index) => {
        if (tempArr.indexOf(workbenchList[index].order_id) == -1) {
          this.workbenchListHandle.push({
            order_id: workbenchList[index].order_id,
            contractNo: workbenchList[index].contractNo,
            handle: workbenchList[index],
            type_name: workbenchList[index].type_name,
            workbench_id: workbenchList[index].workbench_id,
            status: workbenchList[index].status,
            id: workbenchList[index].id,
            starttime: workbenchList[index].starttime,
            endtime: workbenchList[index].endtime,
            content: workbenchList[index].content,
          });
          tempArr.push(workbenchList[index].order_id);
        } else {
          this.workbenchListHandle.forEach((it, i) => {
            if (this.workbenchListHandle[i].order_id == workbenchList[index].order_id) {
              this.workbenchListHandle[i].handle.push(workbenchList[index]);
            }
          })
        }
      })
    },
    // 附件
    getFiled(id){
      this.dialogVisible = true;
      this.$axios.post('/http/api/wanlshop/workbench/workbenchFiled', {
        id: id
      }).then(res =>{
        this.filedList = res.data.data;
      })
    },
    // 下载 pdf
    downloadFile(url){
      window.open(url);
    },
    // 最新公告
    getNews(){
      this.$axios.post('/http/api/wanlshop/article/newsList', {
        "flag": "recommend",
        "id": 144
      }).then(res => {
        this.newList = res.data.data;
      })
    },
    // 新闻列表
    toList(){
      this.$router.push({path:'/newList'})
    },
    // 公告详情
    newDetail(id){
      this.$router.push({path:'/newDetail', query:{id: id}});
    },
    async copyVerificationCode() {
      const codeElement = document.getElementById('verification-code');
      if (codeElement) {
        try {
          await navigator.clipboard.writeText(codeElement.textContent);
          alert('验证码已成功复制到剪贴板！');
        } catch (err) {
          console.error('复制验证码失败：', err);
          alert('复制验证码失败，请手动复制');
        }
      }
    },
    innovateClick(){
      this.getInnovate(this.mobile);
    },
    cardClick(){
      this.dialogtCard = true;
    },
    handleClose(){
      this.dialogtyq = false;
      this.innovateData = {};
    },
    handleCloseCard(){
      this.dialogtCard = false;
    }
  }
}
</script>
<style scoped lang="scss">
.my {
  .kanbanpage {
    .kanban {
      .daiban2{
        height: auto;
        .time{
          font-size: 13px;
          color: #9b9b9b;
        }
      }
      .daiban {
        .statusItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 20px;

          span {
            font-size: 32px;
            font-weight: bold;
          }

          p {
            font-size: 18px;
          }
        }
        .contractNo{
          display: flex;
          align-items: center;
          justify-content: start;
          .hetongIcon{
            width: 24px;
            height: 24px;
            color: #2c57b6;
          }
          .ctNo{
            font-weight: bold;
            margin-left: 5px;
          }
        }
        .listMain{
          margin-top: 12px;
          .orderNo{
            font-weight: bold;
          }
          .filedBtn{
            color: #0081ff;float: right;
            background: #e9e9e9;
            padding: 5px 0;
            border-radius: 4px;
            font-size: 14px;
            width: 90px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .dialogMain{
    li{
      line-height: 24px;
      cursor: pointer;
    }
  }
  .servtit-mb{
    display: none;
  }
  .yhq-con{
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    ::v-deep .el-dialog__header{
      padding: 0;
      z-index: 999;
      position: absolute;
      right: 0;
      .el-dialog__headerbtn{
        top: 15px;
        right: 15px;
      }
    }
  }
  .yhq-tc{
    background: url("../../public/img/yhq-bg.jpg") no-repeat;
    height:330px;    background-size: 100% 100%;
    padding: 30px 30px 10px;
    position: relative;
    .daishiyong{
      position: absolute;
      right: 0;
      top: 0;
    }
    .r-ico{
      position: absolute;
      right: 0;
      bottom: 0;
      width: 340px;
      overflow: hidden;
    }
    .yhq-title{
      font-size: 22px;color: #3971bb;font-weight: bold;padding: 5px 0;
    }
    .number{
      font-size: 75px;
      color: #3971bb;
      font-weight: bold;
      padding: 10px 50px;
      span{
        font-size:60px;
      }
    }
    .verification{
      margin: 10px 0 10px;
      font-size: 16px;
      color: #333;
      p{
        margin: 0 0 8px;
      }
      .blue{
        color: #3971bb;    padding: 0;font-size: 16px;
      }
    }
    .yhq-footer{
      font-size: 16px;
      text-align: center;
      color: #333;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, -50%);
      width: 100%;
    }

  }
  ::v-deep .el-dialog{
    border-radius: 8px;
  }
  ::v-deep .el-dialog__body{
    text-align: center;
    padding-top: 30px;
  }
}
@media only screen and (max-width:768px) {
  .my{
    .navbar-brand{
      padding: 5px 0
    }
    .navbar-toggler{
      display: none;
    }
    .collapse{
      display: block;
    }
    .navbar-text{
      right: 0;top: -10px
    }
    .head-sm-height{
      height: 60px !important;
    }
    .kanbanpage .workcate a{
      padding: 0 10px;margin-right: 0;
    }
    .kanbanbanner{
      overflow: hidden;
      margin: 0 -15px;width: calc(100% + 30px);
      img{
        width: 150%;
      }
    }
    .daiban{
      height: auto;
      .statusItem{
        width: 50%;float: left;
        p{
          font-size: 16px !important;
        }
      }
    }
    .userbx{
      display: none;
    }
    .appliserv{
      .appitem{
        width: 33.33%;float: left;
        h5{
          font-size: 14px;
        }
        img{
          width: 50px;
        }
      }
    }
    .yigouxm{
      margin: 0 -15px 20px;width: calc(100% + 30px);
      padding: 10px;
      h4{
        display: none;
      }
      .yigouxm-con{
        .servtit-mb{
          display: block;margin: 10px 0 5px;
        }
        .yigouxmli-l{
          display: none;
        }
        .yigouxmli-r{
          width:100%;padding-left: 0;
          ul{
            width: 102%;margin: 0 -1%;
          }
          li{
            margin-bottom: 5px;
          }
        }
      }
    }
    .downLoadCard{
      color: #fff;
      background: #f56c6c;
      font-size: 14px;
      padding: 10px;
      border-radius: 3px;
      margin-left: 10px;
    }
  }
  .yhq-con{
    ::v-deep .el-dialog{
      width: 90% !important;
      height: 90vh;
      margin-top: 50px !important;
      margin: 0 auto 50px;
    }
    ::v-deep .transparentDialog{
      width: 80% !important;
      height: auto;
      .el-dialog__body{
        text-align: center;
      }
    }
    .yhq-tc{
      background: url("../../public/img/yhq-bgmb.jpg") no-repeat;
      height: 100%;    background-size: 100% 100%;
      padding:20% 15px 10px;
      .number{
        padding: 8% 0;
        text-align: center;
      }
      .r-ico{
        width: 100%;bottom: 50px;
      }
      .verification{
        ::v-deep .el-col-5{
          width: 60%;
        }
        ::v-deep .el-col-19{
          width: 40%;
        }
      }
    }
    ::v-deep .el-dialog__body{
      height: calc(100% - 0px);
    }
  }
}
</style>
