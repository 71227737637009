<template>
  <div class="my">

    <nav class="navbar navbar-light fixed-top navbar-expand-lg navwhite">
      <div class="container">
        <a class="navbar-brand my-1">
          <img src="../../../public/img/logo.png" class="logo-sm-height" height="40">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <!-- <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="" >首页</a>
            </li>
          </ul> -->
          <span class="navbar-text">
							<a class="gongzuotai">工作台</a>
							<a @click="logout" class="tuchu" v-if="!isMobile">退出登录</a>
            <!-- <span class="reglogbox">
              <a href="" >登录</a>
                /
              <a href="" >注册</a>
            </span> -->
							</span>
        </div>
      </div>
    </nav>

    <!--占位导航栏-->
    <div style="height:80px;" class="head-sm-height"></div>
    <!-- <el-button @click="logout" type="danger">退出登录</el-button> -->

    <div class="kanbanpage">
      <div class="workcate">
        <div class="container">
          <a href="" class="active">服务看板</a>
          <a href="">应用中心</a>
          <a href="">工单管理</a>
          <a href="">团队管理</a>
        </div>
      </div>
      <div class="kanban">
        <div class="container">
          <div class="kanbanbanner">
            <a @click="zhenduan">
              <img src="../../../public/img/kanban-banner.jpg" width="100%"/>
            </a>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="yigouxm">
                <el-row :gutter="20">
                  <el-col :xl="16" :xs="24">
                    <div class="casebase-search">
                      <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="行业" label-width="80px">
                          <el-select v-model="hangYeVal" @change="hangYeChange" placeholder="请选择行业">
                            <el-option
                                v-for="item in industryOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="企业规模" label-width="80px">
                          <el-select v-model="guiMoVal" @change="guiMoChange" placeholder="请选择企业规模">
                            <el-option
                                v-for="item in specificationOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item class="phoneBtn">
                          <el-button type="primary" @click="select">查询</el-button>
                          <el-button type="danger" @click="resetList">重置</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-col>
                </el-row>
                <div class="casebase-list">
                  <div class="casebase-li" v-for="file in fileList" :key="file.id">
                    <el-row :gutter="20">
                      <el-col :xl="16" :xs="24">
                        <span class="casebaseTitle">{{ file.title }}</span><br/>
                        <el-tag type="success" v-for="(tag,index) in file.tags" :key="index" style="margin-right:5px;margin-top:10px;">{{tag}}</el-tag>
                      </el-col>
                      <el-col :xl="8" :xs="24">
                        <div class="casebase-right">
                          <el-button type="primary" @click="previewFile(file)">预览</el-button>
                          <el-button type="success" @click="downloadFile(file)">下载</el-button>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="pdf预览"
        :visible.sync="isPdfDialog"
        width="90%"
        :close-on-click-modal = true
        :before-close="pdfDialogClose">
      <pdf :src="pdfUrl"></pdf>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="isPdfDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pdf from 'vue-pdf'
export default {
  name: 'casebase',
  data() {
    return {
      fileList: [
        // {
        //   name: '2024年度制造业数字化转型典型案例集',
        //   tags: ['通用行业', '全部企业'],
        //   url: 'https://crm.winzome.com/file/xcxDownFile?fileId=6032a076cad7408087752669a632fcfc'
        // }
      ],
      categoryId: '145',
      hangYeVal: '',
      guiMoVal: '',
      industryOptions: [],
      specificationOptions: [],
      isPdfDialog: false,
      pdfUrl: '',
      isMobile: false
    }
  },
  components: {
    pdf
  },
  mounted() {
    this.checkDeviceType();
    window.addEventListener('resize', this.checkDeviceType);
    this.resetList();
    this.getALKHangYe();
    this.getALKGuiMo();
  },
  methods: {
    checkDeviceType() {
      // 你可以根据需要调整这个阈值
      this.isMobile = window.innerWidth <= 768;
    },
    //获取行业分类
    getALKHangYe() {
      this.$axios.get('/http/api/wanlshop/article/aLKHangYe', {}).then(res => {
        let aLKHangYeArray = Object.entries(res.data.data).map(([key, value]) => ({
          label: value,
          value: key
        }));
        this.industryOptions = aLKHangYeArray;
      }).catch((err) => {
        console.log(err)
      })
    },
    //获取规模分类
    getALKGuiMo(){
      this.$axios.get('/http/api/wanlshop/article/aLKHangGuiMo', {}).then(res => {
        let aLKGuiMoArray = Object.entries(res.data.data).map(([key, value]) => ({
          label: value,
          value: key
        }));
        this.specificationOptions = aLKGuiMoArray;
      }).catch((err) => {
        console.log(err)
      })
    },
    hangYeChange(e){
      this.hangYeVal = e;
    },
    guiMoChange(e){
      this.guiMoVal = e;
    },
    // 中小企业数字化转型水平评测
    zhenduan() {
      this.$router.push({
        path: '/zhenduan',
      });
    },
    // 退出登录
    logout() {
      this.$confirm('是否退出当前账号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        localStorage.removeItem('mobile');
        localStorage.removeItem('uscc');
        localStorage.removeItem('company');
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
        this.$router.push('/login');
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    getALKArticleList(categoryId, hangyeVal, guimoVal){
      this.$axios.post('/http/api/wanlshop/article/getALKArticleList', {
        categoryId: categoryId,
        hangYeVal: hangyeVal,
        guiMoVal: guimoVal,
      }).then(res => {
        this.fileList = res.data.data;
      })
    },
    resetList(){
      this.getALKArticleList(this.categoryId, "", "");
      this.hangYeVal = '';
      this.guiMoVal = '';
    },
    select() {
      this.getALKArticleList(this.categoryId, this.hangYeVal, this.guiMoVal);
    },
    previewFile(file) {
      this.isPdfDialog = true;
      this.pdfUrl = '/http' + file.attachfile;
    },
    pdfDialogClose(){
      this.isPdfDialog = false;
    },
    downloadFile(file) {
      // 使用 window.open 方法
      const pdfUrl = '/http' + file.attachfile;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = file.title + '.pdf'; //下载后的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // FileUpload() {
    //   this.$router.push({
    //     path: '/FileUpload',
    //   });
    // },
  }
}
</script>
<style scoped lang="scss">
.my {
  .kanbanpage {
    .kanban {
      .daiban2 {
        height: auto;

        .time {
          font-size: 13px;
          color: #9b9b9b;
        }
      }

      .daiban {
        .statusItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 20px;

          span {
            font-size: 32px;
            font-weight: bold;
          }

          p {
            font-size: 18px;
          }
        }

        .contractNo {
          display: flex;
          align-items: center;
          justify-content: start;

          .hetongIcon {
            width: 24px;
            height: 24px;
            color: #2c57b6;
          }

          .ctNo {
            font-weight: bold;
            margin-left: 5px;
          }
        }

        .listMain {
          margin-top: 12px;

          .orderNo {
            font-weight: bold;
          }

          .filedBtn {
            color: #0081ff;
            float: right;
            background: #e9e9e9;
            padding: 5px 0;
            border-radius: 4px;
            font-size: 14px;
            width: 90px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  .dialogMain {
    li {
      line-height: 24px;
      cursor: pointer;
    }
  }

  .servtit-mb {
    display: none;
  }

  .yhq-con {
    ::v-deep .el-dialog__body {
      padding: 0;
    }

    ::v-deep .el-dialog__header {
      padding: 0;
      z-index: 999;
      position: absolute;
      right: 0;

      .el-dialog__headerbtn {
        top: 15px;
        right: 15px;
      }
    }
  }

  .yhq-tc {
    background: url("../../../public/img/yhq-bg.jpg") no-repeat;
    height: 330px;
    background-size: 100% 100%;
    padding: 30px 30px 10px;
    position: relative;

    .daishiyong {
      position: absolute;
      right: 0;
      top: 0;
    }

    .r-ico {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 340px;
      overflow: hidden;
    }

    .yhq-title {
      font-size: 22px;
      color: #3971bb;
      font-weight: bold;
      padding: 5px 0;
    }

    .number {
      font-size: 75px;
      color: #3971bb;
      font-weight: bold;
      padding: 10px 50px;

      span {
        font-size: 60px;
      }
    }

    .verification {
      margin: 10px 0 10px;
      font-size: 16px;
      color: #333;

      p {
        margin: 0 0 8px;
      }

      .blue {
        color: #3971bb;
        padding: 0;
        font-size: 16px;
      }
    }

    .yhq-footer {
      font-size: 16px;
      text-align: center;
      color: #333;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, -50%);
      width: 100%;
    }

  }

  .wenjian {
    text-align: right;
    line-height: 40px;

    .el-button--primary {
      background: #2c57b6;
      border-color: transparent;
    }
  }

  .casebase-search {
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }

    .el-button--primary {
      background: #2c57b6;
      border-color: transparent;
    }
  }

  .casebase-list {
    border-top: 1px solid #eeeeee;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    .casebase-li {
      border-bottom: 1px solid #eeeeee;
      padding: 10px;
      .casebaseTitle{
        font-size: 18px;
      }
      .casebase-right {
        text-align: right;
        line-height: 70px;
        ::v-deep .el-button {
          padding: 8px 15px;
        }
      }
    }
  }

}

@media only screen and (max-width: 768px) {
  .my {
    .navbar-brand {
      padding: 5px 0
    }

    .navbar-toggler {
      display: none;
    }

    .collapse {
      display: block;
    }

    .navbar-text {
      right: 0;
      top: -10px
    }

    .head-sm-height {
      height: 60px !important;
    }

    .kanbanpage .workcate a {
      padding: 0 10px;
      margin-right: 0;
    }

    .kanbanbanner {
      overflow: hidden;
      margin: 0 -15px;
      width: calc(100% + 30px);

      img {
        width: 150%;
      }
    }

    .daiban {
      height: auto;

      .statusItem {
        width: 50%;
        float: left;

        p {
          font-size: 16px !important;
        }
      }
    }

    .userbx {
      display: none;
    }

    .appliserv {
      .appitem {
        width: 33.33%;
        float: left;

        h5 {
          font-size: 14px;
        }

        img {
          width: 50px;
        }
      }
    }

    .yigouxm {
      margin: 0 -15px 20px;
      width: calc(100% + 30px);
      padding: 10px;

      h4 {
        display: none;
      }
    }
    .phoneBtn{
      margin-top: 15px;
    }
    .casebaseTitle{
      font-size: 16px !important;
    }
  }
  .yhq-con {
    ::v-deep .el-dialog {
      width: 90% !important;
      height: 90vh;
      margin-top: 50px !important;
      margin: 0 auto 50px;
    }

    .yhq-tc {
      background: url("../../../public/img/yhq-bgmb.jpg") no-repeat;
      height: 100%;
      background-size: 100% 100%;
      padding: 20% 15px 10px;

      .number {
        padding: 8% 0;
        text-align: center;
      }

      .r-ico {
        width: 100%;
        bottom: 50px;
      }

      .verification {
        ::v-deep .el-col-5 {
          width: 60%;
        }

        ::v-deep .el-col-19 {
          width: 40%;
        }
      }
    }

    ::v-deep .el-dialog__body {
      height: calc(100% - 0px);
    }
  }
}

</style>
